import React, { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';
import { makeStyles, Theme } from '@material-ui/core';
import HabitsDetails from '@components/FinancialPortal/HabitsDetails/HabitsDetails';
import AccountDetail from '@components/FinancialPortal/AccountDetails/AccountDetails';
import AsociatedProducts from '@components/FinancialPortal/AccountDetails/AsociatedProducts';
import { finerioListBank } from '@data';
import { isBrowser } from '@utils';
import { getFinerioAccounts } from '@apollo';
import { useAuth } from '@hooks';

const accountDetails = ({ location }) => {
  useAuth({ redirectIfNotLogged: true });

  const isMobile = useMediaQuery('(max-width: 1280px)');
  const classes = useStyles();

  // console.log("🚀 « isBrowser", isBrowser());

  if (isBrowser() && !location?.state) {
    navigate(Route.financialPortal);
  }
  const { finerioAccounts, refetchFinerioAccounts } = getFinerioAccounts();
  const bankId = isBrowser() ? location?.state.bankId : '';
  const bank = finerioListBank.find((item) => item.finerioBankId === bankId);
  const accounts = finerioAccounts
    .filter((account: any) => account.bankId === bankId)
    .sort((a, b) => a.accountName.localeCompare(b.accountName));

  const amount = useCallback(() => {
    let amountToReturn = 0;
    accounts.forEach((account: any) => {
      if (account.accountType !== 'Crédito' && account.enabled === true) {
        amountToReturn += account.accountBalance;
      }
    });

    return amountToReturn;
  }, [accounts]);

  return (
    <div className={classes.wholeAccountPage}>
      <div className={classes.accountDetails}>
        <AccountDetail
          title={bank?.name}
          amount={amount}
          backgroundColor={bank?.backgroundColor}
          icon={bank?.icon}
          bankId={bankId}
        />
      </div>
      <div className={classes.associatedProducts}>
        <AsociatedProducts
          accounts={accounts}
          refetchFinerioAccounts={refetchFinerioAccounts}
        />
      </div>
    </div>
  );
};

export default accountDetails;

const useStyles = makeStyles<Theme>((theme) => ({
  wholeAccountPage: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '5rem',
    [theme.breakpoints.down(1280)]: {
      flexDirection: 'column',
      backgroundColor: '#F4F3F3',
      paddingBottom: '0rem',
    },
  },
  accountDetails: {
    minHeight: '100vh',
    [theme.breakpoints.down(1280)]: {
      minHeight: '0',
    },
  },
  associatedProducts: {
    [theme.breakpoints.down(1280)]: {
      paddingTop: '10rem',
    },
  },
}));
