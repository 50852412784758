import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { useStyles } from "./Product.style";
import { Switch } from "@material-ui/core";
import { useApolloClient } from "@apollo/client";
import { disableEnableFinerioAccount } from "@apollo";

interface productProps {
  AccountName: string;
  AccountType: string;
  AccountBalance: number;
  AccountCurrency: string;
  AccountNumber: string;
  Debit: string;
  Credit: string;
  Enabled: boolean;
  AccountId: number;
  refetchFinerioAccounts: () => void;
}

const Product = ({
  AccountName,
  AccountType,
  AccountBalance,
  AccountCurrency,
  AccountNumber,
  Debit,
  Credit,
  Enabled,
  AccountId,
  refetchFinerioAccounts,
}: productProps) => {
  const isMobile = useMediaQuery("(max-width: 1280px)");
  const client = useApolloClient();

  const buttonHandler = (numberId: string) => {
    navigate(Route.habitsDetails + "?#" + numberId);
  };
  const classes = useStyles();
  const CreditJson = {} //JSON.parse(Credit);
  let isCreditNull = false;
  // if (
  //   CreditJson.creditLimit === null &&
  //   CreditJson.closingDate === null &&
  //   CreditJson.lastClosingDate === null &&
  //   CreditJson.nonInterestPayment === null &&
  //   CreditJson.statementBalance === null &&
  //   CreditJson.minimumPayment === null &&
  //   CreditJson.dueDate === null &&
  //   CreditJson.annualPercentageRate === null &&
  //   CreditJson.cardNumber === null &&
  //   CreditJson.availableBalance === null
  // ) {
  //   isCreditNull = true;
  // }
  const [enabled, setEnabled] = useState(Enabled);

  return (
    <>
      {!isCreditNull ? (
        <div className={classes.product}>
          <div className={classes.innerContainer1}>
            <div className={classes.innerContainerRow}>
              <h3 className={classes.cardTitle}>{AccountName}</h3>
              <div className={classes.check}>
                <Switch
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                  checked={enabled}
                  onChange={async () => {
                    const resp = await disableEnableFinerioAccount(client, {
                      accountId: AccountId,
                    });
                    if (resp.disableEnableFinerioAccount.enabled) {
                      refetchFinerioAccounts();
                      setEnabled(true);
                    } else {
                      refetchFinerioAccounts();
                      setEnabled(false);
                    }
                  }}
                />
              </div>
            </div>

            <div className={classes.innerContainerRow}>
              <div className={classes.innerContainerColumn}>
                <h4 className={classes.innerText1}>TIPO DE PRODUCTO</h4>
                <p className={classes.innerText2}>{AccountType}</p>
              </div>
              <div className={classes.innerContainerColumn}>
                <h4 className={classes.innerText1}>BALANCE</h4>
                <p className={classes.innerText2}>
                  $ {AccountBalance.toLocaleString("es")}
                </p>
              </div>
            </div>

            <div className={classes.innerContainerRow}>
              <div className={classes.innerContainerColumn}>
                <h4 className={classes.innerText1}>DIVISA</h4>
                <p className={classes.innerText2}>{AccountCurrency}</p>
              </div>
              <div className={classes.innerContainerColumn}>
                <h4 className={classes.innerText1}>NUMERO DE CUENTA</h4>
                <p className={classes.innerText2}>{AccountNumber}</p>
              </div>
            </div>

            <div className={classes.break}>
              <div className={classes.breakLine}></div>
              <div className={classes.breakText}>Tarjeta de Credito</div>
            </div>

            <div className={classes.innerContainerRow}>
              <div className={classes.innerContainerColumn}>
                <h4 className={classes.innerText1}>Limite de Credito</h4>
                <p className={classes.innerText2}>{CreditJson.creditLimit}</p>
              </div>
              <div className={classes.innerContainerColumn}>
                <h4 className={classes.innerText1}>Closing Date</h4>
                <p className={classes.innerText2}>{CreditJson.closingDate}</p>
              </div>
            </div>
            <div className={classes.innerContainerRow}>
              <div className={classes.innerContainerColumn}>
                <h4 className={classes.innerText1}>Due Date </h4>
                <p className={classes.innerText2}>{CreditJson.dueDate}</p>
              </div>
              <div className={classes.innerContainerColumn}>
                <h4 className={classes.innerText1}>Available Balance</h4>
                <p className={classes.innerText2}>
                  {CreditJson.availableBalance}
                </p>
              </div>
            </div>
            <div className={classes.innerContainerRow}>
              <div className={classes.innerContainerColumn}>
                <h4 className={classes.innerText1}>Statement Balance</h4>
                <p className={classes.innerText2}>
                  {CreditJson.statementBalance}
                </p>
              </div>
              <div className={classes.innerContainerColumn}>
                <h4 className={classes.innerText1}>Card Number</h4>
                <p className={classes.innerText2}>{CreditJson.cardNumber}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.product}>
          <div className={classes.innerContainer2}>
            <div className={classes.innerContainerRow}>
              <h3 className={classes.cardTitle}>{AccountName}</h3>
              <Switch
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
                checked={enabled}
                onChange={async () => {
                  const resp = await disableEnableFinerioAccount(client, {
                    accountId: AccountId,
                  });
                  if (resp.disableEnableFinerioAccount.enabled) {
                    refetchFinerioAccounts();
                    setEnabled(true);
                  } else {
                    refetchFinerioAccounts();
                    setEnabled(false);
                  }
                }}
              />
            </div>

            <div className={classes.innerContainerRow}>
              <div className={classes.innerContainerColumn}>
                <h4 className={classes.innerText1}>TIPO DE PRODUCTO</h4>
                <p className={classes.innerText2}>{AccountType}</p>
              </div>
              <div className={classes.innerContainerColumn}>
                <h4 className={classes.innerText1}>BALANCE</h4>
                <p className={classes.innerText2}>
                  $ {AccountBalance.toLocaleString("es")}
                </p>
              </div>
            </div>

            <div className={classes.innerContainerRow}>
              <div className={classes.innerContainerColumn}>
                <h4 className={classes.innerText1}>DIVISA</h4>
                <p className={classes.innerText2}>{AccountCurrency}</p>
              </div>
              <div className={classes.innerContainerColumn}>
                <h4 className={classes.innerText1}>NUMERO DE CUENTA</h4>
                <p className={classes.innerText2}>{AccountNumber}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Product;
